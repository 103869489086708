.privacy-check-container {
  display: flex;
  align-items: flex-start;
  margin: 1.5rem 0;
  cursor: pointer;

  .ada-ck {
    border-radius: 6px;
    margin-right: 0.4rem;

    &.is-selected {
      background-color: #e9f2df;
      border-color: #739b40;
      color: #739b40;
    }
  }

  .sub-msg {
    flex: 1;
  }

  .privacy-check-label {
    a {
      font-family: "ApexNew-Book";
      color: #667085;
    }
  }

  &.registration-conditions {
    margin-bottom: 32px;
    .title {
      color: FFF;
      margin-bottom: 0.5rem;
    }

    .privacy-check-label,
    .privacy-check-label a {
      line-height: 20px;
      font-family: "ApexNew-Book";
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
    }
  }
}

.privacy-link,
.disclaimer-link {
  text-decoration: underline;
}
