.input-map-container {
  position: relative;

  .overlay-disable-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10;
  }

  .zoom-in,
  .zoom-out,
  .street-satellite-view-container {
    z-index: 9;
    position: absolute;
    right: 16px;
    width: 48px;
    height: 48px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
    color: white;
    font-size: 26px;
    cursor: pointer;
    overflow: hidden;
    background-color: #ccc;
    border-radius: 50%;
    border: 2px solid;
    &.satellitare {
      right: 75px;
      &.satOn {
        border: 2px solid #739b40;
      }
    }
    &.stradale {
      &.streetOn {
        border: 2px solid #739b40;
      }
    }
  }
  .zoom-in {
    bottom: 154px;
    background-color: #739b40;
  }
  .zoom-out {
    bottom: 92px;
    background-color: #739b40;
  }

  .street-satellite-view-container {
    bottom: 28px;
    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .mapboxgl-control-container {
    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
      display: none;
    }
  }
}
