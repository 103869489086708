.sectors-selection-cmp {
  .sector-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 1em;
    color: #344054;
    cursor: pointer;
    .icon-name {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        background-color: #e9f2df;
        padding: 10px;
        border-radius: 50%;
      }
      .item-name {
        font-family: "ApexNew-Medium";
        color: #344054;
        font-size: 16px;
        line-height: 24px;
        margin-left: 20px;
      }
    }

    &.is-selected {
      background-color: #e9f2df;
      border: 1px solid #aacb81;
      .item-name {
        color: #435a25;
      }
    }
  }
  .modal-footer {
    display: flex;
    float: right;
    border-top: 1px solid #eaecf0;
    width: 100%;
    padding-top: 15px;
    margin-top: 15px;
    .buttons-container {
      margin-right: 0px;
      margin-left: auto;
      display: flex;
    }
  }
}
