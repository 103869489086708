textarea {
  resize: vertical;
  height: 7em;
}

.header-cmp {
  background: #0f3250;

  .hd-title {
    color: #fff;
  }

  .hd-back-btn {
    background-color: transparent;
    color: #87b54c;
    border: none;
  }
}

.login-page,
.register-page,
.mail-sent-page {
  background: #0f3250;
  color: #fff;
}

.mail-sent-page {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  .body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #e9f2df;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    svg {
      width: 24px;
      height: auto;
      color: #739b40;
    }
  }

  &__title {
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__text {
    font-size: 18px;
    margin-bottom: 32px;
  }

  &__send-mail {
    font-size: 14px;
    color: #fff;
  }
}

.retrive-pswd-modal {
  position: absolute;

  .modal-header {
    display: block !important;
  }

  .modal-title {
    color: #101828 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}

.resend-mail-modal {
  position: absolute;
  z-index: 2010;
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-backdrop:has(~ .resend-mail-modal) {
  z-index: 2000;
}

.password-step-final {
  &__icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #e9f2df;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    svg {
      width: 24px;
      height: auto;
      color: #739b40;
    }
  }

  &__title {
    font-size: 18px;
    color: #101828;
  }

  &__description {
    font-size: 16px;
    color: #667085;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;

  gap: 8px 0;
}

.password-input-container {
  position: relative;

  &__input {
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 5%;
    width: 15px;
    height: 15px;
    transform: translate(0, -50%);
  }
}

.text-input {
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 16px;
  border: solid #14436b 1px;
  background: #0a2338;
  color: #fff;
}

.text-input-white {
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 16px;
  border: solid #d0d5dd 1px;
  background: #fff;
  color: #0a2338;
}

.text-input-modal {
  background: #ffffff;
  color: #000;
  border: solid #d0d5dd 1px;
  font-size: 16px;
  border-radius: 5px;
  padding: 8px 11px;
}

.text-input:focus,
.text-input-modal:focus {
  outline: none;
}

.modal-description {
  color: #667085;
  font-size: 16px;
  margin-bottom: 16px;
}

.border-green {
  border: 2px solid #739b40 !important;
}

.input-otp {
  .code-item {
    flex: 1;
    height: 48px;
    color: #aacb81 !important;
    border: solid #aacb81 1px !important;
  }

  &.otp-error {
    .code-item {
      color: #f97066 !important;
      border: solid #f97066 1px !important;
    }
  }
}

.error-message {
  color: #f97066;
}

.divisor-line {
  width: 100%;
  height: 1px;
  background: #eaecf0;
}

.no-account-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eaecf0;

  &__left-border,
  &__right-border {
    height: 1px;
    background-color: #eaecf0;
    flex: 1 0 30%;
  }

  &__label {
    flex: 1 0 40%;
    text-align: center;
  }
}

.padding-y-xsmall {
  padding: 2px 0;
}

.padding-y-small {
  padding: 4px 0;
}

.padding-y-base {
  padding: 8px 0;
}

.padding-y-medium {
  padding: 16px 0;
}

.padding-y-large {
  padding: 24px 0;
}

.padding-y-xlarge {
  padding: 32px 0;
}

.margin-y-base {
  margin: 8px 0;
}

.margin-y-medium {
  margin: 16px 0;
}

.margin-y-large {
  margin: 24px 0;
}

.margin-y-xlarge {
  margin: 32px 0;
}
