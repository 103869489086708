.climate-container {
  &__header {
    width: 100%;
    padding: 20px 16px;
    background-image: url(../../assets/images/map-accordion-background.png);
    background-size: cover;
  }

  &__zone-name {
    font-size: 14px;
    color: #fff;
    font-weight: lighter;

    span {
      font-weight: 800;
    }
  }

  &__body {
    padding: 0 16px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__description {
    color: #667085;
    font-size: 16px;
    padding: 16px 0;
  }

  &__button {
    margin-bottom: 32px;
  }
}
