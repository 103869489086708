.regional-data-page {
  .bottom-box {
    padding: 20px 20px;
    box-shadow: 0px -20px 25px #00000014;
    width: 100%;
    border-radius: 15px;
    position: fixed;
    background-color: white;
    bottom: 0px;
    left: 0px;
    height: 90px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    .ada-btn {
      &.w-100 {
        &.max-350 {
          max-width: 350px;
        }
      }
    }
  }
}
