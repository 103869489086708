.save-plan-modal {
  .modal-content {
    .modal-header {
      justify-content: flex-start;
    }

    .modal-body {
      font-family: "ApexNew-Medium";

      .sub-title {
        color: #667085;
        line-height: 24px;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 300;
      }

      .form-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;

        label {
          font-size: 0.85rem;
          margin-bottom: 0.5rem;
        }

        input {
          outline: none;
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          height: 44px;
          font-family: "ApexNew-Book";
          font-size: 1rem;
          padding: 0 0.8rem;
        }
      }
    }

    .modal-footer {
      padding-top: 20px;
    }
  }
}
