.info-page {
  background-color: #e5e5e5;
  height: 100%;
  min-height: 100vh;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  .text-container {
    margin-top: 94px;
    margin-bottom: 30px;
    padding: 0px 25px;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    h2 {
      margin-top: 55px;
      color: #101828;
      &:first-child {
        margin-top: 0px;
      }
    }
    h3 {
      font-family: "ApexNew-Medium";
      font-size: 18px;
      line-height: 28px;
      color: #101828;
      margin-top: 45px;
      text-transform: uppercase;
      &:first-child {
        margin-top: 0px;
      }
    }
    p {
      font-family: "ApexNew-Book";
      margin-bottom: 8px;
    }
    ul {
      padding-left: 30px;
      margin-bottom: 8px;
      li {
        font-family: "ApexNew-Book";
      }
    }
    .line-separation {
      height: 8px;
    }
    span {
      font-family: "ApexNew-Medium";
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 650px) {
  .info-page {
    padding: 20px;
    .text-container {
      margin-top: 79px;
      margin-bottom: 15px;
      padding: 0px;
    }
  }
}
