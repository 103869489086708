.organizations-selection-cmp {
    min-height: 200px;

    .organizations-info {
        font-family: "ApexNew-Book";
        color: #667085;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0.8rem;
    }

    .sector-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;
        height: 25px;
        cursor: pointer;
        .ada-ck {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            border-radius: 6px;
        }

        &.is-selected {
            .ada-ck {
                border-color: #739B40;
                background-color: #E9F2DF;

                svg {
                    color: #739B40;
                    font-size: 14px;
                }
            }
        }

        .item-name {
            font-family: "ApexNew-Medium";
            line-height: 20px;
            color: #344054;
            margin-left: 0.5rem;
        }
    }
}

.modal-container.modal-organisations-selection {
    .modal-header {
        justify-content: flex-start;
    }
}