.location-step {
  .sub-msg {
    margin-bottom: 20px !important;
  }
  .search-area-container {
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    .geocoder-wrapper {
      position: relative;
      flex: 1;

      input {
        width: 100%;
      }
    }
  }
}
