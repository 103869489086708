.ada-wizard-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 130px;
  z-index: 11;

  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .top-border-shadowed {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 12px;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px -20px 24px -4px rgba(16, 24, 40, 0.08), 0px -8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px 12px 0px 0px;
  }

  .wizard-step {
    padding: 10px 0px 15px;
    width: 100%;
    max-width: 512px;

    &.location-step {
      padding: 10px 20px 20px;
      min-height: 260px;
    }

    &.sector-step {
      .sub-msg {
        margin-bottom: 10px !important;
      }
    }

    .main-msg {
      color: #101828;
      font-family: "ApexNew-Medium";
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0.4rem;
    }

    .sub-msg {
      color: #667085;
      font-family: "ApexNew-Book";
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px !important;
    }

    .input-label {
      font-family: "ApexNew-Medium";
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      margin-bottom: 0.4rem;
    }

    .info-label {
      font-family: "ApexNew-Book";
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }

    .geoloc-btn {
      margin-right: 0.8rem;
    }

    .btn-delete-area {
      margin-top: 1.2rem;
    }
    .go-to-location-container {
      .find-zone-btn {
        span {
          font-size: 16px;
          line-height: 24px;
          font-family: "ApexNew-Medium";
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .ada-wizard-container {
    padding-bottom: 0px;
    .wizard-step {
      padding: 10px 20px 20px;
      .main-msg {
        margin-bottom: 5px;
      }
      .sub-msg {
        margin-bottom: 20px;
        line-height: 20px;
      }
      .go-to-location-container {
        .find-zone-btn {
          padding: 9px 20px;
        }
      }
    }
  }
}
