.nav-drawer-container {
  margin-top: 30px;
  color: white;
  .bottom-link-container {
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    .bottom-link {
      font-family: "ApexNew-Medium";
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }

  .bk-img-container {
    position: fixed;
    bottom: 0;
    width: 242px;
    z-index: 1;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
