.loader-container{
    position: absolute;
    top: 0px;
    left:0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background-color: #344054b2;
    &.semi-transparent{
      background-color: rgb(255, 255, 255, 0.6);
    }
    img{
      width:100px;
      /* mix-blend-mode: multiply; */
    }
  }