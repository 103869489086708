.all-risks-cmp {
  margin-top: 1.8rem;
  display: grid;
  margin-top: 1.8rem;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;

  .risk-card {
    position: relative;
    cursor: pointer;
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 128px;
    max-height: 128px;
    margin-bottom: 1.4rem;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #0a2338 0%, rgba(10, 35, 56, 0) 100%);
    }

    .ev-card-content {
      font-family: "ApexNew-Medium";
      position: relative;
      z-index: 9;
      display: flex;
      justify-content: space-between;
      color: #fff;
      padding: 1rem;
      font-size: 18px;
      line-height: 28px;
      .ev-label {
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        text-transform: lowercase;
        font-family: "ApexNew-Medium";
        &::first-letter {
          text-transform: uppercase;
        }
      }

      svg {
        font-family: "ApexNew-Book";
        font-size: 1.5rem;
      }
    }

    .ev-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 992px) {
  .all-risks-cmp {
    margin-top: 1.8rem;
    display: grid;
    grid-template-columns: 50% auto;
  }
}

@media (max-width: 650px) {
  .all-risks-cmp {
    margin-top: 1.8rem;
    display: grid;
    grid-template-columns: auto;
    .risk-card {
      height: 96px;
      max-height: 96px;
    }
  }
}
