.personal-area-drawer-container{
    color:white;
    margin-top:30px;
    .welcome-text{
        font-size: 20px;
        line-height: 30px;
        font-family: "ApexNew-Book";
    }
    .center-q{
        font-size: 18px;
        margin: 40px auto 30px;
        line-height: 28px;
    }    
    button{
        margin:24px auto;
        &.border-green{
            border:2px solid #739b40;
        }
    }
    .bottom-q{
        font-size: 16px;
        margin-top:30px
    }
}