.add-new-zone {
    height: 100%;
    border: solid #5B7B33 2px;
    border-radius: 10px;
    min-width: 144px;
    min-height: 229px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;

    &__icon {
        color: #5B7B33;
        font-size: 20px;
    }

    &__title {
        color: #5B7B33;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
    }
}