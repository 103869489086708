.status-selection-cmp {
  min-height: 200px;

  .status-info {
    font-family: "ApexNew-Book";
    color: #667085;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.8rem;
  }

  .sector-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
    height: 25px;
    cursor: pointer;

    .ada-ck {
      width: 20px;
      height: 20px;
      border: 1px solid #d0d5dd;
      border-radius: 50%;
    }

    &.is-selected {
      .ada-ck {
        border-color: #739b40;
        background-color: #e9f2df;

        svg {
          color: #739b40;
          font-size: 12px;
        }
      }
    }

    .item-name {
      font-family: "ApexNew-Medium";
      line-height: 20px;
      color: #344054;
      margin-left: 0.5rem;
    }
  }

  .expire-date-section {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;

    &.show {
      height: 160px;
    }

    .expire-date-info {
      margin-top: 28px;
      margin-bottom: 16px;
      font-family: "ApexNew-Book";
      color: #667085;
      font-size: 14px;
      line-height: 22px;
    }

    .date-container {
      margin-bottom: 28px;
      width: 95%;
      display: flex;
      width: 100%;

      >div {
        flex: 1;
      }

      .ada-input {
        font-family: "ApexNew-Book";
        font-size: 16px;
        line-height: 24px;
        color: #101828;
        text-transform: capitalize;

        .date-icon {
          display: inline-block;
          margin-left: auto;
          color: #667085;
        }
      }

      .clear-icon-btn {
        cursor: pointer;
        padding: 0 16px;
        border: none;
        outline: none;
        background-color: transparent;
        color: #667085;
        font-size: 22px;
      }
    }
  }
}

.modal-container.modal-status-selection {
  .modal-header {
    justify-content: flex-start;
  }
}