.danger-info {
  $rootSelector: &;

  display: flex;
  column-gap: 8px;

  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px;

  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }

  &--low {
    background-color: #e9f2df;

    svg {
      color: #87b54c;
    }

    #{$rootSelector}__text {
      color: #5b7b33;
    }
  }

  &--medium {
    background-color: #fffaeb;

    svg {
      width: 8px;
      height: 8px;

      color: #f79009;
    }

    #{$rootSelector}__text {
      color: #b54708;
    }
  }

  &--high {
    background-color: #fef3f2;

    svg {
      color: #f04438;
    }

    #{$rootSelector}__text {
      color: #b42318;
    }
  }

  &__text {
    font-weight: bold;
    font-size: 12px;
  }
}
