.risk-item-page {
  .risk-item-header {
    position: relative;
    height: 192px;
    max-height: 192px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(10, 35, 56, 0) 0%, #0a2338 100%);
    }

    .ev-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .header-content {
      position: relative;
      z-index: 8;
      padding: 1rem;
      font-size: 36px;
      line-height: 44px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;

      .back-btn {
        padding: 0;
        color: #fff;
        font-size: 28px;
      }

      .risk-label {
        font-family: "ApexNew-Medium";
        padding: 0px 16px;
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -0.02em;
        color: #ffffff;
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  .risk-item-container {
    display: grid;
    grid-template-columns: 50% auto;
    margin-top: 2rem;
    .risk-item-body,
    .solutions-list-container {
      padding: 1rem;
    }

    .risk-item-body {
      font-family: "ApexNew-Book";
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      .description {
        font-size: 20px;
        line-height: 30px;
        color: #667085;
        font-family: "ApexNew-Book";
      }
    }

    .solutions-list-container {
      .title {
        font-family: "ApexNew-Medium";
        font-size: 18px;
        line-height: 28px;
        color: #101828;
      }
      .ada-btn {
        &.filter {
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 18px;
          margin-top: 30px;
          padding-left: 15px;
          padding-right: 15px;
          border: 1px solid #d0d5dd;
          margin-bottom: 10px;
          img {
            margin-right: 5px;
          }
          span {
            font-family: "ApexNew-Medium";
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }
        }
      }
      .active-filter {
        background: #e9f2df;
        border-radius: 16px;
        padding: 5px 10px;
        border: 0px;
        margin: 5px;
        div {
          display: flex;
          p {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #5b7b33;
            font-family: "ApexNew-Medium";
          }
          svg {
            font-size: 15px;
            margin-left: 5px;
            margin-top: 3px;
            color: #5b7b33;
            font-family: "ApexNew-Medium";
          }
        }
      }

      .solutions-list {
        margin-top: 1rem;
        list-style: none;

        .solutions-list-item {
          cursor: pointer;
          font-family: "ApexNew-Medium";
          border-top: 1px solid #eaecf0;
          padding: 1rem 2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
          color: #5b7b33;
          p {
            font-family: "ApexNew-Medium";
            font-size: 14px;
            line-height: 20px;
            color: #5b7b33;
          }
          .solution-item-title {
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .risk-item-page {
    .risk-item-header {
      .header-content {
        .risk-label {
          font-size: 42px;
          line-height: 44px;
        }
      }
    }
    .risk-item-container {
      display: grid;
      grid-template-columns: auto;
      margin-top: 2rem;
    }
  }
}

@media (max-width: 650px) {
  .risk-item-page {
    .risk-item-header {
      .header-content {
        .risk-label {
          font-size: 36px;
          line-height: 44px;
        }
      }
    }
    .risk-item-container {
      .risk-item-body {
        .description {
          font-size: 14px;
          line-height: 20px;
          font-family: "ApexNew-Book";
        }
      }
    }
  }
}
