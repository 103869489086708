.personal-area-header {
  justify-content: space-between;
  padding: 0 16px;

  &__button-wrapper {
    display: flex;
    column-gap: 16px;
  }

  &__button {
    background: transparent;
    border: none;

    svg {
      color: #87b54c;
      height: 24px;
      width: 24px;
    }
  }
}
