.aoi-solution-detail-page {
  .solution-detail-body {
    padding-bottom: 130px;
  }
  .bottom-box {
    padding: 20px;
    box-shadow: 0px -20px 25px #00000014;
    width: 100%;
    border-radius: 12px 12px 0px 0px;
    position: fixed;
    background-color: white;
    bottom: 0px;
    left: 0px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      max-width: 650px;
      display: block;
      button {
        margin-left: auto;
      }
    }
  }
}
