.zone-card {
  font-family: "Apex New", sans-serif;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  position: relative;
  overflow: hidden;

  &__header {
    padding: 16px 10px;
    position: relative;
    min-height: 46px;
  }

  &__title {
    color: #101828;
    font-size: 14px;
    line-height: 18px;
    font-weight: 550;
    margin-bottom: 0;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    font-size: 18px;
    top: 8px;
    right: 12px;
    color: #667085;
  }

  &__image {
    width: 144px;
    height: 128px;
    object-fit: cover;
  }

  &__content {
    position: relative;
    cursor: pointer !important;
    width: 100%;
    height: 128px;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;

    &__map_placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      .map-placeholder-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        color: #5b7b33;
      }
    }
  }

  &__footer {
    background: #f3f8ed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px;
  }

  &__footer-text {
    font-size: 14px;
    color: #5b7b33;
    font-weight: 550;
  }

  &__footer-icon {
    font-size: 16px;
    color: #5b7b33;
    margin-left: 0.5rem;
  }
}

.delete-zone-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(217, 45, 32, 0.4);

  &.show {
    display: flex;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    gap: 8px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 18px;
    margin-bottom: 0.8rem;
    font-family: "ApexNew-Medium";
    min-width: 100px;
    line-height: 20px;
    cursor: pointer;

    &.danger-btn {
      background: #D92D20;
      border: 1px solid #D92D20;
      color: #fff;
    }
  }
}