.personal-area {
  font-family: "Apex New", sans-serif;

  &__page-title {
    padding-top: 24px;
  }

  &__download-pdf {
    color: #5B7B33;
    cursor: pointer;
    font-weight: bold;
  }
}

.heading-1 {
  font-size: 24px;
  font-weight: 400;
}

.heading-1-2 {
  font-size: 24px;
  font-weight: 550;
}

.heading-2 {
  font-size: 18px;
  font-weight: 400;
}

.paragraph {
  color: #667085;
  font-size: 16px;
  line-height: 24px;
}

.color-dark {
  color: #101828;
}

.color-light {
  color: #667085;
}

.personal-area-page {
  .date-str {
    text-transform: capitalize;
  }
}

.export-excel-container {
  display: flex;
  justify-content: center;
}

.status {
  background-color: #fff;
  padding: 16px;
  margin: 24px 0;
}