#modal-slide {
  height: 0px;
  display: flex;
  justify-content: center;
}

.modal-backdrop {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background-color: #344054b2;
  &:hover {
    cursor: pointer;
  }
}

.modal-container {
  z-index: 1000;
  position: fixed;
  width: 90%;
  max-width: 512px;
  display: flex;
  align-items: center;
  height: 100vh;
  pointer-events: none;
  .modal-content {
    background-color: white;
    padding: 15px;
    border-radius: 15px;
    height: fit-content;
    position: fixed;
    pointer-events: all;
    width: 100%;
    max-width: 512px;
    &.loader {
      background-color: transparent;
      display: flex;
      justify-content: center;
      img {
        width: 100px;
      }
    }
    .modal-header {
      display: flex;
      width: 100%;
      .modal-title {
        font-family: "ApexNew", sans-serif;
        font-style: normal;
        font-family: "ApexNew-Medium";
        font-size: 18px;
        line-height: 28px;
        color: #101828;
      }
      .closeButton {
        position: absolute;
        top: 7px;
        right: 0px;
      }
      .closeIcon {
        width: 18px;
        height: 18px;
      }
    }
    .modal-body {
      max-height: 90vh;
      overflow-x: hidden;
    }
    .modal-footer {
      display: flex;
      float: right;
      border-top: 1px solid #eaecf0;
      width: 100%;
      padding-top: 15px;
      margin-top: 15px;
      .buttons-container {
        margin-right: 0px;
        margin-left: auto;
        display: flex;
      }
    }
    .filter-modal-body {
      h3 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #eaecf0;
        line-height: 0.1em;
        margin: 30px 0 30px;
        font-size: 14px;
        text-align: center;
        color: #667085;
        span {
          background: #fff;
          padding: 0 10px;
          font-family: "ApexNew-Book" !important;
        }
      }
      .checkbox-container {
        display: flex;
        margin-bottom: 5px;
        .ada-ck {
          border-radius: 6px;
          margin-right: 0.4rem;

          &.is-selected {
            background-color: #e9f2df;
            border-color: #739b40;
            color: #739b40;
          }
        }
        p {
          font-family: "ApexNew-Medium";
          font-size: 14px;
          line-height: 20px;
          color: #344054;
        }
      }
    }
  }
  &.right,
  &.left {
    width: 375px;
    max-width: 375px;
    .modal-content {
      background-color: #0f3250;
      height: 100vh;
      border-radius: 0px;
      max-width: 375px;
    }
    .modal-header {
      .modal-title {
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
        padding-right: 25px;
      }
    }
  }

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }
}

@media (max-width: 550px) {
  .modal-container {
    z-index: 1000;
    position: relative;
    width: 90%;
    max-width: 512px;
    display: flex;
    align-items: center;
    height: 100vh;
    pointer-events: none;
    &.right,
    &.left {
      width: 100%;
      position: fixed;
      .modal-content {
        width: 100%;
        .modal-header {
          justify-content: left;
          .closeButton {
            display: block;
          }
        }
      }
    }
    .modal-content {
      width: 90%;
      .modal-header {
        justify-content: center;
        .closeButton {
          display: none;
        }
      }
      .modal-footer {
        .buttons-container {
          display: flex;
          width: 100%;
          flex-direction: column-reverse;
          .ada-btn {
            width: 100%;
            &.white-btn {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
