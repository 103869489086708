.regional-report-page {
  .dual-card-box {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    column-gap: 20px;

    &.link-card {
      cursor: pointer;
    }

    .card-green-body {
      .info-text {
        font-family: "ApexNew-Book";
        font-size: 14px;
        line-height: 20px;
        color: #667085;
        margin-top: 10px;
      }

      .double-column {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        >div {
          grid-column: span 6;
          margin-top: 25px;

          h4 {
            font-size: 14px;
            line-height: 20px;
            color: #344054;
            font-family: "ApexNew-Medium";
          }

          .season-select {
            padding: 7px 5px;
            background: #ffffff;
            border: 1px solid #d0d5dd;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            max-width: 120px;
            font-size: 16px;
            line-height: 24px;
            color: #101828;
            font-family: "ApexNew-Book";
            text-transform: capitalize;
          }

          .double-option-container {
            display: flex;

            .circle-double-option {
              border-radius: 50%;
              background-color: white;
              border: 1px solid #d0d5dd;
              width: 34px;
              height: 34px;
              padding: 0px;

              &:last-child {
                margin-left: 5px;
              }

              &.true {
                background-color: #e9f2df;
                border: 1px solid #e9f2df;
              }
            }
          }
        }

        .temp-minima,
        .temp-massima {
          font-family: "ApexNew-Medium";
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.02em;

          p {
            font-family: "ApexNew-Medium";
          }

          h5 {
            font-size: 16px;
            line-height: 24px;
            color: #667085;
            letter-spacing: 0em;
            margin-bottom: 5px;
          }

          &.span12 {
            grid-column: span 12;
          }
        }

        .temp-minima {
          color: #2479c1;
        }

        .separator-line-gray {
          grid-column: span 12;
          margin: 25px 0px 0px;
        }

        .temp-massima {
          color: #d92d20;
        }
      }
    }
  }

  .green-box {
    padding: 16px;
    background: #f3f8ed;
    border: 1px solid #aacb81;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 120px;

    h3 {
      font-size: 16px;
      line-height: 24px;
      color: #5b7b33;
      margin-top: 0px;
    }

    .inline-block {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon-text {
        display: flex;
        align-items: center;

        .circle-icon {
          width: 48px;
          height: 48px;
          background: #d8e7c5;
          border: 8px solid #e9f2df;
          border-radius: 28px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            color: #739b40;
          }
        }

        .text-text {
          display: flex;
          align-items: center;
          margin-left: 15px;

          p,
          span {
            font-size: 16px;
            line-height: 24px;
            color: #739b40;
          }

          p {
            font-family: "ApexNew-Book";
          }

          span {
            font-family: "ApexNew-Medium";
            margin-left: 5px;
          }
        }
      }
    }

    .modifica-zona-btn {
      margin-left: 20px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 22px;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      font-family: "ApexNew-Medium";
    }
  }

  .bottom-box {
    padding: 20px;
    box-shadow: 0px -20px 25px #00000014;
    width: 100%;
    border-radius: 12px 12px 0px 0px;
    position: fixed;
    background-color: white;
    bottom: 0px;
    left: 0px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;

    div {
      max-width: 650px;
      display: block;

      p {
        font-family: "ApexNew-Book";
        font-size: 16px;
        line-height: 24px;
        color: #667085;
      }

      .buttons-box {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        button {
          margin-top: 10px;
          width: 100%;

          &.save-zona {
            background-color: #e9f2df;
            border: 1px solid #e9f2df;
            color: #5b7b33;
          }

          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.modal-container {
  &.regional-report-modal {
    .modal-content {
      .modal-header {
        .closeButton {
          display: none;
        }

        .closeIcon {
          display: none;
        }
      }

      p {
        color: #667085;
        font-size: 16px;
        line-height: 24px;
        font-family: "ApexNew-Book";
      }

      img {
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
        margin-right: auto;
        margin-left: auto;
      }

      .custom-modal-footer {
        border-top: 1px solid #eaecf0;
        margin-top: 15px;
        display: flex;
        justify-content: center;

        button {
          width: 100%;
          margin-top: 5px;

          &:hover {
            background-color: white;
          }
        }
      }
    }

    .long-text-modal {
      span {
        font-family: "ApexNew-Medium";

        .green-text {
          color: #87b54c;
        }

        .red-text {
          color: #f04438;
        }
      }
    }
  }
}

.add-area-name-modal {
  .sub-title {
    color: #667085;
    font-size: 16px;
    line-height: 24px;
  }

  input {
    width: 100%;
    border: 1px solid #D0D5DD;
    height: 44px;
    border-radius: 8px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding: 10px 14px;
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .regional-report-page {
    .dual-card-box {
      display: grid;
      grid-template-columns: auto;
    }

    .green-box {
      .inline-block {
        display: block;

        .icon-text {
          .text-text {
            display: block;

            span {
              margin-left: 0px;
            }
          }
        }

        .modifica-zona-btn {
          margin-top: 25px;
          width: 100%;
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 350px) {

  .temp-minima,
  .temp-massima {
    p {
      font-size: 30px;
    }
  }
}