.white-header {
  background-color: #fff;
  .help-button {
    margin-left: auto;
    margin-right: 10px;
    width: auto;
    height: auto;
    padding: 0px;
    border: 0px solid;
    background-color: transparent;
    img {
      color: black;
    }
  }
}
