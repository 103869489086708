.submenu {
  &__item {
    cursor: pointer;
    display: flex;
    padding: 16px 0;
    font-size: 14px;
    font-weight: bold;
    color: #5b7b33;
    justify-content: space-between;
    border-top: solid #eaecf0 1px;
  }
}
