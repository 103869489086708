.nav-card-container {
  padding: 20px;
  display: flex;
  margin-top: 20px;
  background-color: #0a2338;
  color: white;
  border-radius: 10px;
  text-decoration: none;
  position: relative;
  z-index: 1;

  &.no-link {
    cursor: unset;
  }

  img {
    height: 24px;
    margin: 5px 20px 0px 10px;
  }

  .nav-card-right-side {
    margin-right: 5px;

    h3 {
      font-family: "ApexNew-Medium";
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px;
    }

    p {
      font-family: "ApexNew-Book";
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }

  &.reversed {
    background-color: #f2f8fd;
    color: #000000;
  }
}

.mini-nav-card {
  border-top: 1px solid #eaecf0;
  color: #5b7b33;
  max-width: 600px;
  width: 100%;
  display: flex;
  text-decoration: none;

  p {
    padding: 20px 15px;
    flex-basis: 90%;
    font-style: normal;
    font-family: "ApexNew-Medium";
    font-size: 14px;
    line-height: 20px;
  }

  img {
    padding: 20px 0px;
    width: 12px;
  }
}

.card-green-footer-container {
  background-color: white;
  box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
  border-radius: 8px;
  margin: 20px 0px;
  height: fit-content;

  .card-green-header {
    display: flex;
    padding: 15px;
    position: relative;
    border-bottom: 1px solid #eaecf0;

    p {
      font-family: "ApexNew-Medium";
      font-size: 18px;
      line-height: 28px;
    }

    a {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .card-green-body {
    padding: 15px;
  }

  .green-footer {
    background-color: #f3f8ed;
    color: #5b7b33;
    display: flex;
    justify-content: flex-end;
    padding: 15px 10px;
    text-decoration: none;

    &.no-link {
      min-height: 50px;
    }

    p {
      font-family: "ApexNew-Medium";
      font-size: 14px;
      line-height: 20px;
    }

    img {
      width: 8px;
      height: 13px;
      margin: 4px 5px 0px 10px;
    }
  }
}

.seasonal-report-card-container {
  h3 {
    font-family: "ApexNew-Medium";
    font-size: 18px;
    line-height: 28px;
    color: #667085;
  }

  p {
    font-family: "ApexNew-Book";
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    margin-bottom: 20px;
  }

  .progress-circle-container {
    position: relative;
    height: 150px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ada-circular-progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      width: 50px;
      height: 50px;
    }

    .season-icon-container {
      border-radius: 50%;

      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 10;

      &.icons {
        background-color: white;
      }

      span {
        font-size: 12px;
        font-family: "ApexNew-Medium";
        white-space: nowrap;
        background-color: white;
        padding: 2px 0px;
      }

      &.Autunno {
        top: 108px;
        left: calc(50% - 66px);
      }

      &.Inverno {
        top: 15px;
        left: calc(50% - 66px);
      }

      &.Primavera {
        top: 16px;
        left: calc(50% + 39px);
      }

      &.Estate {
        top: 108px;
        left: calc(50% + 39px);
      }
    }
  }

  .separator-line-gray {
    height: 1px;
    margin: 0px;
    width: calc(100% + 30px);
    background-color: #eaecf0;
    margin-left: -15px;
  }

  .upper-footer {
    margin: 10px 0px;

    >h4 {
      font-family: "ApexNew-Medium";
      font-size: 12px;
      line-height: 18px;
      color: #667085;
      text-transform: uppercase;
      margin-bottom: 15px;
      margin-top: 20px;
    }

    .danger-level {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      .season-level {
        grid-column: span 3;

        h4 {
          font-family: "ApexNew-Medium";
          font-size: 12px;
          line-height: 18px;
          color: #667085;
        }

        .risk-color-box {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 2px 8px 2px 6px;
          border-radius: 16px;
          width: fit-content;
          height: 22px;

          img {
            width: 11px;
            height: 11px;
            margin-top: 0px;
            margin-right: 5px;
          }

          p {
            font-family: "ApexNew-Medium";
            margin: 0px;
            font-size: 12px;
            line-height: 18px;
          }

          &.Alta {
            background-color: #fef3f2;

            p {
              color: #b42318;
            }
          }

          &.Media {
            background-color: #fffaeb;

            p {
              color: #b54708;
            }
          }

          &.Bassa {
            background-color: #e9f2df;

            p {
              color: #5b7b33;
            }
          }
        }

        .risks-numbers-item {
          width: 100%;
          margin-top: 0.3rem;
          padding-left: 0.7rem;
          .risks-numbers {
            margin-bottom: 0px;
            color: #667085;

            .val {
              color: #101828;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .card-green-footer-container {
    max-width: calc(100%);
    margin: 20px 0px;
  }
}

@media (max-width: 375px) {
  .upper-footer {
    .danger-level {
      .season-level {
        h4 {
          word-spacing: 25px;
        }
      }
    }
  }
}