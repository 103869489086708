.all-risks-page {
  background-color: #f2f4f7;
  .title {
    font-family: "ApexNew-Medium";
    color: #101828;
    font-size: 24px;
    line-height: 32px;
    margin-top: 1.8rem;
  }

  .sub-msg {
    font-family: "ApexNew-Book";
    color: #667085;
    line-height: 24px;
    margin-top: 0.4rem;
  }
}
