.e3ci-cmp {
  .e3ci-body {
    margin-top: 35px;
  }
  .title {
    font-family: "ApexNew-Medium";
    color: #344054;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  .e3ci-stress-list-section {
    margin-top: 0.4rem;
    ul.e3ci-stress-list {
      padding-bottom: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      width: fit-content;
      padding-left: 0px;

      li {
        white-space: nowrap;
        border: 1px solid #d0d5dd;
        border-radius: 18px;
        padding: 8px 12px;
        color: #667085;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        transition: background-color 0.3s linear, color 0.3s linear, border-color 0.3s linear;
        font-family: "ApexNew-Medium";
        &.selected {
          color: #5b7b33;
          background: #e9f2df;
          border-color: transparent;
        }
      }
    }
  }

  .e3ci-chart-section {
    width: 100%;
    .e3ci-chart-container {
      position: relative;
      min-height: 300px;
      transform: translateX(-25px);
      display: flex;
      justify-content: flex-start;
      .recharts-surface {
        tspan {
          font-size: 12px;
          line-height: 18px;
          font-family: "ApexNew-Book";
        }
      }
    }

    .selected-period-value-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      color: #101828;

      label {
        font-family: "ApexNew-Medium";
        font-size: 18px;
        line-height: 28px;
      }
      span.value {
        font-family: "ApexNew-Heavy";
        font-size: 24px;
        line-height: 32px;
        margin-left: 0.5rem;
        margin-bottom: 0.2rem;
      }
    }
    .how-to-read-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 30px;
    }
  }

  .e3ci-reference-period-section {
    max-width: 50%;
    .date-container {
      margin-top: 0.2rem;

      .ada-input {
        font-family: "ApexNew-Book";
        font-size: 16px;
        line-height: 24px;
        color: #101828;
        text-transform: capitalize;
        .date-icon {
          color: #667085;
        }
      }
    }
  }

  .e3ci-secription-section {
    margin-top: 1.3rem;

    .description {
      font-family: "ApexNew-Book";
      font-size: 20px;
      line-height: 30px;
      color: #667085;
    }
  }
}

@media (max-width: 992px) {
  .e3ci-cmp {
    .e3ci-stress-list-section {
      ul.e3ci-stress-list {
        overflow-x: scroll;
        width: 100%;
      }
    }
  }
}

@media (max-width: 650px) {
  .e3ci-cmp {
    .e3ci-reference-period-section {
      max-width: 100%;
    }
  }
}
