.weather {
  padding: 16px 0;
  border-bottom: solid #eaecf0 1px;

  &__title {
    font-size: 16px;
    font-weight: 550;
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    padding: 8px 0;
  }

  &__season {
    flex: 1;
    font-size: 12px;
    color: #667085;
  }

  &__danger-info-container {
    flex: 2;
  }
}
