.cookie-banner {
    padding: 20px 0;
    background-color: rgba(15, 50, 80, 0.95);
    color: #fff;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 9999999;

    a {
        color: #fff;
    }

    &__close {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
    }

    &__content {
        padding: 0 16px;
    }

    &__description {
        padding-bottom: 16px;
    }
}