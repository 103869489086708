.home-landing-page {
  background-color: #fff;
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    "mainimg maintext"
    "cert cert";
  align-items: start;
  max-width: 1480px;
  margin: auto;
  padding-bottom: 0px;
  padding-top: 15vh;
  .side {
    &.left-side {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .left-side {
    grid-area: mainimg;
  }

  .right-side {
    grid-area: maintext;
  }

  .cert {
    grid-area: cert;
  }

  .main-img-container {
    img {
      width: 480px;
      min-width: 280px;
      max-width: 100%;
    }
  }

  .main-title {
    font-family: "ApexNew-Heavy";
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 1em;
  }

  p,
  ul,
  li {
    font-style: normal;
    font-family: "ApexNew-Book";
    font-size: 20px;
    line-height: 30px;
    color: #101828;
  }

  ul.list-info {
    margin-top: 1rem;
    padding-left: 2rem;
    margin-bottom: 2rem;
  }

  .c-image-container {
    display: flex;
    justify-content: center;
    grid-column: span 2;
    img {
      width: 290px;
    }
  }
  .bottom-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .start-app-btn {
      max-width: 480px;
    }
  }
}

@media (max-width: 992px) {
  .home-landing-page {
    .main-title {
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      margin-bottom: 15px;
    }
    p,
    ul,
    li {
      font-style: normal;
      font-family: "ApexNew-Book";
      font-size: 18px;
      line-height: 24px;
    }
    .info {
      margin: 10px 0px;
    }
    .bottom-box {
      margin-top: 20px;
    }
  }
}

@media (max-width: 650px) {
  .home-landing-page {
    padding-top: 84px;
    grid-template-areas:
      "mainimg"
      "maintext"
      "cert";
    .right-side {
      margin-top: -120px;
    }
    .main-title {
      margin-bottom: 1em;
    }
    p,
    ul,
    li {
      line-height: 28px;
    }
    .info {
      margin: 15px 0px;
    }
    .c-image-container {
      margin-top: 0em;
      margin-bottom: 9em;
    }
    .bottom-box {
      padding: 20px 20px;
      box-shadow: 0px -20px 25px #00000014;
      width: 100%;
      border-radius: 15px;
      position: fixed;
      background-color: white;
      bottom: 0px;
      left: 0px;
      height: 90px;
      margin-bottom: 0px;
    }
  }
}
