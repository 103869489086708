.stars-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 38px;

  .star-item {
    font-size: 38px;
    cursor: pointer;
    margin: 0 2px;

    svg {
      color: rgba(115, 155, 64, 0.55);
      transition: 0.3s;
      margin: 0 8px;
    }

    &.is-hovered {
      svg {
        color: rgba(115, 155, 64, 1);
      }
    }

    &.is-selected {
      svg {
        color: #739b40;
      }
    }
  }

}

.modal-container.feedback-modal {
  .modal-content {
    padding: 20px;

    .modal-header {
      .modal-title {
        font-size: 26px;
        margin-bottom: 18px;
      }
    }

    .modal-body {
      .modal-description {
        font-size: 18px;
        margin-bottom: 30px;
        line-height: 26px;

        &.modal-error-msg {
          margin-top: 24px;
          color: #f97066;
        }

        &.cancel-cta {
          margin-top: 24px;
          margin-bottom: 8px;
          font-size: 15px;
          cursor: pointer;
          text-align: center;
          color: #939bab;
          
          span.txt {
            position: relative;
            display: inline-block;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              height: 1px;
              width: 100%;
              background-color: #939bab;
            }
          }
        }
      }

      textarea {
        color: #232323;
        resize: none;
        overflow-y: auto;
        margin-bottom: 16px;

        &::placeholder {
          color: #667085;
          font-size: 15px;
          line-height: 22px;
          font-style: italic;
        }
      }

      .feedback-success-modal-body {

        .main-side {
          display: flex;
          align-items: center;

          .ok-check-icon-container {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #e9f2df;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;

            svg {
              width: 24px;
              height: auto;
              color: #739b40;
            }
          }
        }

        .main-msg {
          font-size: 22px;
          color: #101828;
          margin-left: 16px;
        }

        .secondary-msg {
          font-size: 16px;
          color: #667085;
          line-height: 22px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .stars-container {
    .star-item {
      font-size: 32px;

      svg {
        margin: 0 6px;
      }
    }
  }

  .modal-container.feedback-modal {
    .modal-content {
      .modal-header .closeButton {
        display: block;
      }

      .modal-body {
        .modal-description {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 355px) {
  .stars-container {
    .star-item {
      font-size: 28px;

      svg {
        margin: 0 6px;
      }
    }
  }

  .modal-container.feedback-modal {
    .modal-content {
      .modal-header {
        justify-content: flex-start;
      }

      .modal-body {
        .modal-description {
          text-align: left;
        }
      }
    }
  }
}