.regional-solution {
  .intro-p {
    font-family: "ApexNew-Book";
    font-size: 20px;
    line-height: 30px;
    color: #667085;
    margin-top: 50px;
  }

  .accordion {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    column-gap: 20px;
    margin-top: 35px;
    .accordion__item {
      background-color: white;
      padding-bottom: 6px;
      margin: 0px 0px 20px;
      height: fit-content;
      border-radius: 10px;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      .color-line {
        height: 28px;
        border-radius: 7px;
        margin-bottom: -20px;
      }
      .fragmented-line {
        height: 28px;
        border-radius: 7px;
        margin-bottom: -20px;
        display: flex;

        .fragment {
          width: calc(25% - 2px);
          margin-bottom: 20px;
          border-radius: 7px;
          margin-right: 1px;
          margin-left: 1px;
          position: relative;
          &:first-child {
            border-radius: 100px 50px 50px 10px;
            margin-right: 2px;
            margin-left: 0px;
          }
          &:last-child {
            border-radius: 50px 100px 0px 50px;
            margin-right: 0px;
            margin-left: 2px;
          }
          &.LOW {
            background-color: #87b54c;
          }
          &.MEDIUM {
            background-color: #f79009;
          }
          &.HIGH {
            background-color: #f04438;
          }
          .season-icon-container {
            &.text {
              width: 50px;
              left: calc(50% - 25px);
              border-radius: 5px;
              height: auto;
              top: 3px;
            }

            border-radius: 50%;
            background-color: white;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 10;
            left: calc(50% - 11px);
            top: -1px;
            span {
              font-size: 11px;
            }
          }
        }
      }
      .accordion-header {
        border-radius: 0px;
        background-color: white;
        display: flex;
        padding: 15px 20px;
        align-items: center;
        h3 {
          font-size: 18px;
          line-height: 28px;
          font-family: "ApexNew-Book";
          margin: 0px 20px;
          color: #5b7b33;
        }
        .accordion__heading {
          margin-left: auto;
        }
        .expand-icon-container {
          border-radius: 50%;
          background-color: #e9f2df;
          padding: 8px;
          border: 0px;
          -webkit-transition: -webkit-transform 0.4s ease-in-out;
          -ms-transition: -ms-transform 0.4s ease-in-out;
          transition: transform 0.4s ease-in-out;
          &.open {
            transform: rotate(135deg);
          }
        }
      }
      .accordion__panel {
        background-color: white;
        ul {
          padding-left: 0px;
          list-style: none;
          li {
            padding: 0px;
            border-top: 1px solid #eaecf0;
            button {
              background-color: white;
              width: 100%;
              border: 0px;
              font-size: 14px;

              display: flex;
              align-items: center;
              box-shadow: 0px 0px;
              padding: 12px 20px;
              text-align: left;
              span {
                font-family: "ApexNew-Medium";
                line-height: 20px;
                color: #5b7b33;
                padding-right: 5px;
                &:first-letter {
                  text-transform: uppercase;
                }
              }
            }
            img {
              margin-left: auto;
              margin-right: 5px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .regional-solution {
    .intro-p {
      font-family: "ApexNew-Book";
      font-size: 18px;
      line-height: 30px;
      color: #667085;
      margin-top: 25px;
    }
    .accordion {
      display: grid;
      grid-template-columns: auto;
    }
  }
}

.added-to-plan {
  padding: 0 8px;
  background-color: #ecfdf3;
  border-radius: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  right: 40px;
  column-gap: 8px;

  svg {
    color: #12b76a;
    width: 10px;
    height: 10px;
  }

  span {
    color: #027a48;
    font-weight: bold;
    font-size: 12px;
  }
}
