.app-header {
  background-color: #0f3250;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .hide-element {
    visibility: hidden;
    pointer-events: none;
  }
}

button {
  cursor: pointer;
}
