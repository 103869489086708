.contatta-assistenza {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  .body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon-wrapper {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #e9f2df;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    svg {
      width: 24px;
      height: auto;
      color: #739b40;
    }
  }

  &__title {
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 500;
  }

  &__text {
    font-size: 18px;
    margin-bottom: 32px;
    color: #667085;
  }
}
