.area-input-page {
  height: calc(100vh - 64px);
  display: grid;
  grid-template-rows: 1fr minmax(130px, auto);
}

@media (max-width: 450px) {
  .area-input-page {
    height: calc(100vh - 64px);
    display: grid;
  }
}
