.white-header {
    background-color: #fff;
}

.closeBtn {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;

    svg {
        font-size: 32px;
    }
}