.carousel-container {
  margin-top: 2rem;
  margin-bottom: 2rem;

  .reg-data-ev-carousel {
    padding-bottom: 2.5rem;

    div[class^="sliderTray___"] {
      width: fit-content !important;
      div[class^="slide___"] {
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    .carousel__dot-group {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      button[class^="dot___"] {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 50%;
        border: none;
        background-color: #d8e7c5;

        &.carousel__dot--selected {
          background-color: #5b7b33;
        }
      }
    }

    .swiper-slide-item {
      width: 256px !important;
      .carousel-item {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        // border: 1px solid #eaecf0;
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        border-radius: 8px;
        height: 352px;
        overflow: hidden;
        padding-bottom: 1rem;

        .img-container {
          height: 192px;
          max-height: 192px;
          .ev-image {
            width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .info-container {
          flex: 1;
          padding: 1rem 1rem 0px;
          overflow-y: auto;

          .main-info {
            font-family: "ApexNew-Bold"; 
            font-size: 30px; 
            line-height: 38px;
            color: #101828;
            margin: 8px 0px;
          }

          .sub-info {
            font-family: "ApexNew-Medium";
            font-size: 14px;
            line-height: 20px;
            color: #667085;
            margin: 8px 0px;
          }

          .additional-info {
            font-family: "ApexNew-Book";
            font-size: 12px;
            line-height: 18px;
            color: #667085;
            margin: 8px 0px;
          }
        }
      }
    }
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: 650px) {
  .carousel-container {
    margin-bottom: 0;
    .carousel__slider {
      width: 100%;
      overflow: unset;
      .carousel__slider-tray-wrapper {
        .carousel__slider-tray {
          transform: none !important;
          flex-wrap: wrap;
          justify-content: flex-start;

          
          div[class^="slide___"] {
            margin-bottom: 36px;
          }
        }
      }
    }

    .carousel__dot-group {
      display: none !important;
    }
  }
}
