ol {
  li {
    text-decoration: underline;
  }
}

.list-margin {
  margin-left: 40px;
}

.tableTerms {
  table-layout: auto;
  width: 100%;
  border:solid black 1px;

  td {
    padding: 5px;
    border: solid black 1px;
  }
}

.terms-condition-container {
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  h2 {
    font-style: normal;
    font-family: "ApexNew-Medium";
    font-size: 24px;
    line-height: 32px;
    margin-top: 4px;
  }
  .page-body {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .text-container {
      max-width: 992px;
      h3 {
        font-style: normal;
        font-family: "ApexNew-Bold";
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
        font-style: normal;
        font-family: "ApexNew-Book";
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .terms {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .terms-condition-container {
    .ada-btn {
      padding-left: 0px;
    }
    .page-body {
      margin-top: 30px;
    }
  }
}
