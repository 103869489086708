.solution-detail-cmp {
  .value-costs-benefits {
    cursor: pointer;
  }

  .header-cmp.white-header .hd-title {
    color: #101828;
  }
  .solution-detail-body {
    .solutions-info-table-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0.7rem 0 1.4rem;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      position: relative;
      background-color: white;

      .info-table-item {
        font-family: "ApexNew-Medium";
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #eaecf0;
        border-right: 1px solid #eaecf0;
        padding: 1.3rem 0.8rem;
        color: #344054;

        .label {
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          font-family: "ApexNew-Medium";
        }

        .ev-image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.8rem 0 1rem;
        }

        .value {
          font-family: "ApexNew-Medium";
          text-transform: uppercase;
          background: #f2f4f7;
          border-radius: 16px;
          padding: 0.2rem 0.7rem;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
        }

        .icons-list {
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-item-container {
            position: relative;
            margin-right: 0.7rem;
            &:last-child {
              margin-right: 0rem;
            }
            .opacity-layer {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.65);
            }
          }
        }
      }
      .info-table-item-border-center {
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        background-color: #eaecf0;
      }
    }

    .ref-projects-container {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      p {
        font-family: "ApexNew-Medium";
        color: #5b7b33;
        font-size: 16px;
        line-height: 24px;
      }
      img {
        margin-left: 0.8rem;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .title {
      font-family: "ApexNew-Medium";
      color: #344054;
      font-size: 18px;
      line-height: 28px;
      margin: 8px 0px;
    }

    .description {
      font-family: "ApexNew-Book";
      font-size: 20px;
      line-height: 30px;
      color: #667085;
      margin: 8px 0px;
    }
  }
}

@media (max-width: 992px) {
  .solution-detail-cmp {
    .header-cmp.white-header {
      background-color: #f2f4f7;
      align-items: flex-start;
      height: 92px;

      .ada-btn.white-btn {
        background-color: transparent;
        border: none;
      }

      .hd-title {
        padding-top: 6px;
        padding-right: 4px;
        padding-left: 8px;
        font-size: 20px;
        line-height: 28px;

        &.can-be-big {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    .solution-detail-body {
      padding-top: 0rem;
      .solutions-info-table-container {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media (max-width: 450px) {
  .solution-detail-cmp {
    .header-cmp.white-header {
      .hd-title {
        &.can-be-big {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}
