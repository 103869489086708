.ada-accordion {
  font-family: "Apex New", sans-serif;

  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%),
    0px 2px 4px -2px rgb(16 24 40 / 6%);
  margin: 24px 0;
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 10px;
    padding: 20px 16px;
  }

  &__title {
    color: #5b7b33;
    font-size: 18px;
    font-weight: 550;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: #e9f2df;
    border: none;
  }

  &__icon {
    width: 18px;
    height: 18px;
    color: #797e74;

    transform: rotate(0);
    transition-duration: 0.3s;

    &.accordion-opened {
      transform: rotate(45deg);
      transition-duration: 0.3s;
    }
  }
}
