.risks-analysis-section {
    .dati-type-selector {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.7rem 0;

        .dati-type-selector-item {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0.4rem 0.7rem 0.5rem;
            margin-right: 0.7rem;
            color: #667085;
            border: 1px solid;
            border-radius: 50px;

            &.is-selected {
                background-color: #E9F2DF;
                color: #5B7B33;
            }
        }
    }

    .dual-card-box {

        .card-green-body {
            .season-select {
                padding: 7px 5px;
                background: #ffffff;
                border: 1px solid #d0d5dd;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
                max-width: 120px;
                font-size: 16px;
                line-height: 24px;
                color: #101828;
                font-family: "ApexNew-Book";
                text-transform: capitalize;
            }

            .double-option-container {
                display: flex;

                .circle-double-option {
                    border-radius: 50%;
                    background-color: white;
                    border: 1px solid #d0d5dd;
                    width: 34px;
                    height: 34px;
                    padding: 0px;

                    &:last-child {
                        margin-left: 5px;
                    }

                    &.true {
                        background-color: #e9f2df;
                        border: 1px solid #e9f2df;
                    }
                }
            }
        }

    }
}