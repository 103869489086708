.white-page-layout {
  background-color: #fff;

  .header-cmp {
    background: #fff;

    .hd-title {
      color: #101828;
    }

    .ada-btn {
      color: #667085;
    }
  }
}

.submenu {
  padding: 16px;
}

.exit-container {
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.no-padding {
  padding: 0 !important;
}