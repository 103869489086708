@font-face {
  font-family: "ApexNew-Thin";
  src: url("../fonts/ApexNew-Thin.otf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "ApexNew-Thin-Italic";
  src: url("../fonts/ApexNew-ThinItalic.otf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "ApexNew-Light";
  src: url("../fonts/ApexNew-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ApexNew-Light-Italic";
  src: url("../fonts/ApexNew-LightItalic.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "ApexNew-Book";
  src: url("../fonts/ApexNew-Book.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ApexNew-Book-Italic";
  src: url("../fonts/ApexNew-BookItalic.otf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "ApexNew-Medium";
  src: url("../fonts/ApexNew-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ApexNew-Medium-Italic";
  src: url("../fonts/ApexNew-MediumItalic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "ApexNew-Bold";
  src: url("../fonts/ApexNew-Bold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "ApexNew-Bold-Italic";
  src: url("../fonts/ApexNew-BoldItalic.otf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "ApexNew-Heavy";
  src: url("../fonts/ApexNew-Heavy.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ApexNew-Heavy-Italic";
  src: url("../fonts/ApexNew-HeavyItalic.otf");
  font-weight: 700;
  font-style: italic;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "ApexNew", sans-serif;
}

:root {
  --swiper-theme-color: #5b7b33 !important;
}

body {
  margin: 0;
  overflow-y: scroll;
  color: #101828;
  background-color: #fff;
  font-size: 14px;

  .app {
    font-family: "ApexNew", sans-serif;
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: "ApexNew-Medium";
  line-height: 1.2;
}

h1 {
  font-size: 2.1875rem;
}

h2 {
  font-size: 1.75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page-cmp {
  background-color: #f2f4f7;
  padding: 64px 16px;
  min-height: 100vh;

  &.page-with-header {
    &.full-page {
      padding-top: 64px;
    }
  }

  &.page-with-no-header {
    padding: 0px;
  }

  &.full-page {
    margin: 0;
    padding: 0;
  }

  &.light-gray-bg {
    background-color: #f2f4f7;
    display: flex;
    justify-content: center;

    h3 {
      font-family: "ApexNew-Book";
      font-size: 18px;
      line-height: 28px;
      color: #667085;
      margin-top: 30px;

      &.font-medium {
        font-family: "ApexNew-Medium";
      }
    }
  }
}

.max-width-container {
  max-width: 1480px;
}

.max-1480-container {
  max-width: 1480px;
  margin: 0px auto;
}

.width-1480-element {
  width: 1480px;
  margin: 0px auto;
}

a {
  color: #5b7b33;
}

.link-like {
  text-decoration: underline;

  &.green {
    color: #5b7b33;
  }

  &:hover {
    cursor: pointer;
  }
}

.ada-btn {
  outline: none;
  text-decoration: none;
  border: 1px solid #739b40;
  background-color: #739b40;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  padding: 12px 20px 12px 20px;
  font-family: "ApexNew-Medium";
  line-height: 24px;
  font-size: 1rem;
  position: relative;
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 1;
  }

  &.w-100 {
    width: 100%;
  }

  &.secondary-btn {
    border: 1px solid #739b40;
    background-color: #fff;
    color: #739b40;
  }

  &.light-btn {
    background-color: #e9f2df;
    border: 1px solid #e9f2df;
    color: #5b7b33;
    width: 44px;
    height: 44px;
  }

  &.semi-transparent-btn {
    background-color: transparent;
    border: none;
    color: #667085;
    width: 44px;
    height: 44px;
  }

  &.white-btn {
    background-color: white;
    color: #667085;
    border-color: white;
    box-shadow: 0px 0px;
    margin-right: 5px;
    padding: 10px;

    &:hover {
      background-color: #f5f5f587;
    }
  }

  &.transparent-btn {
    background-color: transparent;
    border: none;
    box-shadow: 0px 0px;
  }

  &.icon-text-btn {
    svg {
      margin-right: 0.7em;
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.info-btn {
    font-size: 14px;
    line-height: 20px;
    background-color: transparent;
    border: 0px;
    color: #5b7b33;
    padding: 0px;
    box-shadow: 0px 0px;
    font-family: "ApexNew-Medium";
  }
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.only-desktop {
  display: block;
}

.only-mobile {
  display: none;
}

.ada-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.2rem 1rem;

  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #667085;
  font-family: "ApexNew-Book";
  font-size: 1rem;
  line-height: 24px;
  height: 44px;

  &.btn-select {
    cursor: pointer;
  }

  &.with-icon {
    justify-content: space-between;
  }

  &:focus-visible {
    outline: #aacb81 auto 1px;
    outline: none !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(135, 181, 76, 0.3);
    border: 1px solid #aacb81;
  }
}

.no-mb {
  margin-bottom: 0px !important;
}

.super-mb {
  margin-bottom: 200px !important;
}

.header-cmp {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.hd-title {
  color: #101828;
  font-family: "ApexNew-Medium";
  font-size: 24px;
  line-height: 32px;
  font-family: "ApexNew-Medium";

  &:first-letter {
    text-transform: uppercase;
  }
}

.hd-back-btn {
  margin: 0 0.5rem;
  font-size: 1.4rem;
}

p.page-title {
  color: #101828;
  font-family: "ApexNew-Medium";
  font-size: 24px;
  line-height: 32px;
  margin: 24px 0px;
}

p.info {
  color: #667085;
  font-family: "ApexNew-Book";
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0px;
}

.separator-line-gray {
  height: 1px;
  margin: 0px;
  background-color: #eaecf0;
}

/*   ------------------------------------    ADA Checkbox    ------------------------------------    */
.ada-ck {
  outline: none;
  background: none;
  border: 1px solid #d0d5dd;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    display: none;
  }

  &.is-selected {
    background-color: #739b40;
    border-color: #739b40;
    color: #fff;

    svg {
      display: block;
    }
  }
}

/*   ------------------------------------    ADA Checkbox    ------------------------------------    */

/*   ------------------------------------    suggestion-wrapper    ------------------------------------    */

.suggestion-wrapper {
  z-index: 99;
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow: auto;
  list-style: none;
  border: 1px solid #d0d5dd;
  border-top: none;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  background: #fff;

  .suggestion {
    height: 42px;
    background: none;
    padding: 0 30px 0 16px;
    border-bottom: 1px solid #d0d5dd;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #667085;
    font-size: 0.9rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.page-with-detail-header {
  .white-header {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(8px);
  }

  .page-detail-body {
    margin-top: 64px;
    padding: 3rem 1rem 1rem;
  }
}

/*   ------------------------------------    React-toastify    ------------------------------------    */
.ada-toast.success-toast {
  background-color: #F3F8ED;
  border: 1px solid #AACB81;
  box-shadow: none;

  .Toastify__toast-body {
    color: #5B7B33;
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 20px;

    .Toastify__toast-icon {
      display: none;
    }

    .toast-container {
      padding: 0.2rem;
      padding-right: 0.7rem;
    }

    .toast-msg {
      margin-bottom: 1rem;

      .title-msg {
        display: inline-block;
        font-weight: 500;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }

    .link-msg {
      font-weight: bold;
      position: relative;
      width: auto;
      display: inline-block;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #AACB81;
      }
    }

    .close-toast-btn {
      cursor: pointer;
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
      width: 1.7rem;
      height: 1.7rem;
      outline: none;
      border: none;
      background-color: transparent;
      color: #5B7B33;
      font-size: 1rem;
      font-weight: 100;
    }

  }

  .Toastify__close-button {
    display: none;
  }
}

/*   ------------------------------------    MEDIA QUERY    ------------------------------------    */

@media (max-width: 1500px) {
  .width-1480-element {
    width: 100%;
    margin: 0px auto;
  }
}

@media (max-width: 650px) {
  .only-desktop {
    display: none;
  }

  .only-mobile {
    display: block;
  }

  .page-cmp {
    .bottom-box {
      padding: 20px;
      box-shadow: 0px -20px 25px #00000014;
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      position: fixed;
      background-color: white;
      bottom: 0px;
      left: 0px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div {
        max-width: 650px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p.info {
          margin: 0.2rem 0 1.2rem;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .page-with-detail-header {
    .page-detail-body {
      margin-top: 92px;
    }
  }
}



/*   ------------------------------------    DATEPICKER    ------------------------------------    */

.react-datepicker {
  font-size: 1rem;
  padding: 20px;
  button.react-datepicker__navigation {
    top: 20px;
    &.react-datepicker__navigation--previous {
      left: 20px;
    }
    &.react-datepicker__navigation--next {
      right: 20px;
    }
    span {
      &::before {
        border-color: #667085;
        border-width: 2px 2px 0 0;
        height: 6px;
        width: 6px;
        top: 50%;
      }
    }
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: #fff;
      color: #344054;
      padding: 0.7rem 0px;
      border-bottom: 0px;
      font-size: 16px;
      line-height: 24px;
      font-family: "ApexNew-Medium";
    }
  }

  .react-datepicker__month.react-datepicker__monthPicker {
    .react-datepicker__month-wrapper {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;

      .react-datepicker__month-text {
        width: 100px;
        border-radius: 20px;
        padding: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #344054;
        text-transform: capitalize;
        font-size: 14px;
        line-height: 20px;
        font-family: "ApexNew-Book";
        &.react-datepicker__month-text--keyboard-selected {
          color: #fff;
          background: #739b40;
        }

        &.react-datepicker__month--disabled {
          color: rgba(52, 64, 84, 0.4);
          font-weight: normal;
        }
      }
    }
  }
}